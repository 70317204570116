import React from 'react'
import { graphql } from 'gatsby'
import EntitiesAsArray from '../baseComponents/EntitiesAsArray.js'


export const query = graphql`
fragment PropertyDomainComponent on Query {
  rdfProperty(id: {eq: $id}) {
    domain {
      id
      label {
        _language
        _value
      }
      comment {
        _language
        _value
      }
    }
  }
}
`

export default function PropertyDomainComponent(props) {
  const attrs = { entities: props.data.rdfProperty.domain }
  return (
    <EntitiesAsArray {...attrs} />
  )
}
