import React from 'react'
import { graphql } from "gatsby"
import Mermaid from "react-mermaid2"
import erDiagramBuilder from '../../../mermaidUtils/erDiagramBuilder.js'

// for visual validation, see : http://localhost:1111/nfr/utilise
// another exemple to test: http://localhost:1111/nfr/aPourPrerequis


export const query = graphql`
fragment MermaidPropertiesComponent on Query {
  rdfProperty(id: {eq: $id}) {
    id
    label {
      _language
      _value
    }
    hasPropertyShape {
      id
      maxCount
      minCount
    }
    domain {
      id
      label {
        _language
        _value
      }
    }
    range {
      id
      ... on rdfs_Class {
        label {
          _language
          _value
        }
      }
      ... on rdfs_Datatype {
        label {
          _language
          _value
        }
      }
    }
  }
}
`

// @Deprecated
// @TODO: rename it PropertyMermaidComponent
export default function MermaidPropertiesComponent(props) {

  // default for testing chart
  const chart = erDiagramBuilder(props.data)

  return (
    <>
    <p>Note: cette représentation n'est - pour l'heure - pas exacte car dépendante de <a href='https://gitlab.com/mmorg/nodefr-2/-/issues/27'>cette issue</a></p>
    <Mermaid chart={chart}
    config = {{
      theme: "default"
    }}
    />
    </>
  )
}
