import React from 'react'
// import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import createLink from '../../componentUtils/createLink.js'
import getLocalizedLiterals from '../../../jsonldUtils/getLocalizedLiterals.js'

console.warn('@TODO: see how to remplace deprecated @mui/style')
// const useStyles = makeStyles({
//   table: {
//     minWidth: 700,
//   },
//   propsFrom: {
//     'background-color': '#3f51b559'
//   }
// })


export default function EntitiesAsArray(props) {
  // const cssClasses = useStyles()

  const { entities } = props

  if(!entities) return (<p>No entities to display</p>)
  return (
    <>
      <TableContainer component={Paper}>
        {/* <Table className={cssClasses.table} aria-label="spanning table"> */}
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entities.map(getEntityRaw)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )

}

function getEntityRaw(entity, index) {
  return (
    <TableRow key={`${entity.id}-${index}`}>
      <TableCell>{createLink(entity)}</TableCell>
      <TableCell>{entity.comment ? getLocalizedLiterals(entity.comment)[0] : ''}</TableCell>
    </TableRow>
  )
}
