import React from 'react'
import { graphql } from 'gatsby'
import EntitiesAsArray from '../baseComponents/EntitiesAsArray.js'

export const query = graphql`
fragment PropertyRangeComponent on Query {
  rdfProperty(id: {eq: $id}) {
    range {
      id
      ... on rdfs_Class {
        label {
          _language
          _value
        }
        comment {
          _language
          _value
        }
      }
      ... on rdfs_Datatype {
        label {
          _language
          _value
        }
      }

    }
  }
}
`

export default function PropertyRangeComponent(props) {
  const attrs = { entities: props.data.rdfProperty.range }
  return (
    <EntitiesAsArray {...attrs} />
  )
}
