
// use the id name because mermaid don't accept spaces or accents
const getLibelle = (n) => n.id.split(':')[1]

export default function erDiagramBuilder(rdfsModel) {
    const { rdfProperty } = rdfsModel
    const propLabel = getLibelle(rdfProperty)
    const relations = getRelations(rdfProperty)
    const diagram = relations.reduce((acc, rel) => {
        acc = `${acc}
    ${rel.join(' ')} : ${propLabel}`
        return acc
    }, '')

    return `
erDiagram
    ${diagram}
`

}

// @TODO: update this function when shacl is fixed with `sh:NodeShape`
// for now this creates relations for each domain - range tuple
// next version should take in account the NodeShape and associated cadinalities
function getRelations(rdfProperty) {

    const relations = rdfProperty.domain ? rdfProperty.domain.map(domain => {
        const card = getCardinalities()
        if (!rdfProperty.range) return [[getLibelle(domain), card, 'range_not_defined']]
        return rdfProperty.range.map(range => [getLibelle(domain), card, getLibelle(range)])
    }) : []

    return relations.flat(1)
}

// @TODO: manage cardinalities from shape, for now put all in 0..N
// erDiagram cardinalities documentation : https://mermaid-js.github.io/mermaid/#/entityRelationshipDiagram?id=relationship-syntax
function getCardinalities() {
    const left = '}o'
    const right = 'o{'
    const identification = getIdentification()
    return `${left}${identification}${right}`
}

// @TODO: Who to define if identification is `identifying` or `non-identifying`
// doc: https://mermaid-js.github.io/mermaid/#/entityRelationshipDiagram?id=identification
function getIdentification() {
    // const identifyingRel = '--'
    const nonIdentifyingRel = '..'
    return nonIdentifyingRel
}