import React from 'react'
import { graphql } from "gatsby"
import LiteralsAsText from '../baseComponents/LiteralsAsText'

export const query = graphql`
fragment PropertyLiteralsComponent on Query {
  rdfProperty(id: {eq: $id}) {
    id
    label {
      _language
      _value
    }
    comment {
      _language
      _value
    }
    example {
      _language
      _value
    }
    status
  }
}
`

export default function PropertyLiteralsComponent(props) {

  const { rdfProperty: entity } = props.data

  const conf = {
    entity,
    literalsConfig: ['comment', 'example', 'status']
  }

  return (
    <LiteralsAsText {...conf}/>
  )
}
